<template>
  <div class="order_detail">
    <div class="basic_info">
      <div class="title">订单基本信息</div>
      <div class="info_box">
        <div class="item">订单编号：<span class="value">{{detail.orderNumber}}</span></div>
        <div class="item">下单时间：<span class="value">{{detail.createTime}}</span></div>
        <div class="item">服务名称：<span class="value">{{detail.goodsName}}</span></div>
        <div class="item">服务次数：<span class="value">{{detail.useNumber}}/{{detail.number}}</span></div>
        <div class="item">订单套餐：<span class="value">{{detail.specName}}</span><span class="text-14" v-if="!!customTag(detail)">-</span>{{customTag(detail)}}</div>
        <div class="item" v-if="detail.specTemplateId === 3">单次配送：<span class="value">{{ detail.onceNumber }}{{detail.stipulate}}</span></div>
        <div class="item">支付时间：<span class="value">{{detail.paySuccessTime}}</span></div>
        <div class="item">状态：<span class="value">{{orderStatus[detail.status]}}</span></div>
        <div class="item" v-if="detail.remark">订单取消原因：<span class="value">{{detail.remark}}</span></div>
<!--        <div class="item">订单二维码：：<span class="value">JZ202308080001</span></div>-->
      </div>
    </div>

    <div class="serve_info">
      <div class="title">服务信息</div>
      <ch-table :render-option="serveInfoOption" :data="serveInfo" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:discount="scope">
          <div>
            <div v-if="scope.row.instantDiscount">立减：-{{scope.row.instantDiscount}}元</div>
            <div v-if="scope.row.couponUseDetailVo">
              优惠码：-{{scope.row.couponPrice}}元
              <el-tooltip effect="dark"  placement="top">
                <div  slot="content" >
                  优惠券码：{{scope.row.couponUseDetailVo.code}}元
                </div>
                <i class='el-icon-warning-outline'></i>
              </el-tooltip>
            </div>
            <div v-if="scope.row.activityList.length>0">
              <div  v-for="(item) in scope.row.activityList" :key="item.id">
                {{item.name}}：-{{item.amount}}元
              </div>
            </div>
            <div v-if="scope.row.voucherDiscount">
              抵扣券：-{{scope.row.voucherDiscount}}元
              <el-tooltip effect="dark"  placement="top">
                <div slot="content" >
                  <div v-for="(item) in scope.row.voucherList" :key="item.id">
                    抵扣券：{{item.name}}：-{{item.realDiscountValue}}元
                  </div>
                </div>
                <i class='el-icon-warning-outline'></i>
              </el-tooltip>
            </div>
            <div v-if="scope.row.price === scope.row.amount">0元</div>
          </div>
        </template>
        <template v-slot:amount="scope">
          <div>{{scope.row.amount}}元 <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" /></div>
        </template>
      </ch-table>
    </div>

    <div class="serve_bill">
      <div class="title">服务账单</div>
      <ch-table :render-option="tableOption" :data="detail.serviceOrderList" class="mt-10"
                :border="true" :header-cell-style="{background:'#FAFAFA'}"
                :props="{width: '100%',  size: 'mini'}">
        <template v-slot:status="scope">
          <div>{{serveStatus[scope.row.status]||'一'}}</div>
        </template>
        <template v-slot:detail="scope">
          <ch-button type="link" @click="openOrderDetail(scope.row.id)" >详情</ch-button>
        </template>
      </ch-table>
    </div>

    <div class="flowList">
      <div class="title" style="padding-bottom: 10px;">订单进度</div>
      <el-timeline>
        <el-timeline-item
            v-for="(item, index) in detail.flowList"
            :key="index" :color="index===0?'#3f7eff':''"
            >
          <div class="flowList_warp">
            <div class="detail">{{item.detail}}</div>
            <div class="createTime">{{item.createTime}}</div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      detail:{},
      serveInfo:[],
      orderStatus:['待支付','已取消','待使用','已过期','已完成','已退款中','已退款'],
      serveStatus:['待支付','待服务','已取消','已评价','已完成','服务中','待验收','待接单','待签到','申诉中','夜间待支付','爽约'],
    }
  },
  created() {
    if(this.$route.query.orderId){
      this.getOrderDetail(this.$route.query.orderId)
    }
  },
  computed: {
    serveInfoOption() {
      return [
        {column: "text", label: "服务名称", prop: "goodsName",},
        {column: "text", label: "服务明细", prop: "serveDetail", showOverflowTooltip: true,},
        {column: "text", label: "服务时长", prop: "duration", show: [1].includes(this.detail.specTemplateId)},
        {column: "text", label: "原价", prop: "price", sign: "元",},
        {column: "slot", label: "优惠金额", slotName: "discount"},
        {column: "slot", label: "订单金额", slotName: "amount",},
      ]
    },
    tableOption() {
      return [
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: "text", label: "交易时间", prop: "createTime",},
        {column: "text", label: "订单号", prop: "orderNumber",},
        {column: "text", label: "服务订单号", prop: "serverNumber"},
        {column: "text", label: "订单平均金额", prop: "price",},
        {column: "slot", label: "状态", slotName: "status",},
        {column: "slot", label: "详情", slotName: "detail",},
      ];
    }
  },
  methods: {
    getOrderDetail(orderId) {
      this.$curl.get('/hm/order/orderDetail/'+orderId).then(res => {
        this.detail = res.data
        const {serveDetail,duration} = this.detail
        this.serveInfo = [
          {
            ...this.detail,
            duration: duration? duration+'小时':'一',
            serveDetail: serveDetail? JSON.parse(serveDetail).join() : '',
          }
        ]
      })
    },
    openOrderDetail(orderId){
      this.$router.push({ name: "serviceDetail", query: { orderId } });
    },
    customTag(row) {
      return row.customTag ? row.customTag : row.duration ? row.duration + '小时' : ''
    }
  }
}
</script>
<style scoped lang="scss">
.order_detail{
  background: #FFFFFF;
  height: 100%;
  width: 100%;
  margin-top: 15px;
  overflow: auto;
}

.basic_info,
.serve_info,
.serve_bill,
.flowList{
  margin: 0 42px;
  padding: 16px 0;
  .title{
    font-weight: bold;
    padding-bottom: 2px;
  }
}
.basic_info{
  border-bottom: 1px solid #E8E8E8;
  .info_box{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 33.33%;
      padding-top: 10px;
      font-size: 14px;
      .value{
        color: #666666;
      }
    }
  }
}
.flowList{
  .flowList_warp{
    padding-bottom: 10px;
    .createTime{
      color: #999999;
      padding-top: 10px;
    }
  }
}

::v-deep(.el-table td.el-table__cell){
  border-bottom: 1px solid #ebeef5;
}
</style>